import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import './HomeMobile.scss';
import portadaImage from '../../assets/img/portadaWeb.webp';
import { Button, TextField } from '@mui/material';
import que_es from '../../assets/img/que_es.webp';
import nosotros from '../../assets/img/nosotros.webp';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import EmailIcon from '@mui/icons-material/Email';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HomeMobile = () => {
    function importAll(r) {
        return r.keys().map(r);
    }

    const images = importAll(require.context('../../assets/img/grid/webp', false, /\.webp$/));
    const [selectedImage, setSelectedImage] = useState(null);
    const handleClick = (image) => {
        setSelectedImage(image);
    };

    const handleClose = () => {
        setSelectedImage(null);
    };

    const [formData, setFormData] = useState({
        nombre: '',
        telefono: '',
        correo: '',
        mensaje: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateForm = () => {
        const { nombre, telefono, correo, mensaje } = formData;
        return nombre && telefono && correo && mensaje;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        emailjs.send(
            'service_yg57weq',
            'template_05jjez3',
            formData,
            'pq6xSpapgHBxGBFJI'
        ).then((result) => {
            toast.success("Mensaje enviado correctamente");
        }, (error) => {
            toast.error("Error al enviar el mensaje");
        });

        setFormData({
            nombre: '',
            telefono: '',
            correo: '',
            mensaje: ''
        });
    };

    const { ref: firstItemRef, inView: firstItemVisible } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const { ref: secondItemRef, inView: secondItemVisible } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <div>
            <ToastContainer />
            <div className='cabecera'>
                Kendo Zaragoza
            </div>
            <div className='portada'>
                <img className='imagen' src={portadaImage} alt="portada kendo zaragoza" />
                <div>
                    <h1>Kendo Zaragoza</h1>
                    <p>Descubre la magia del kendo en Zaragoza</p>
                    <a href="#contacto">  <Button variant="contained" color='secondary' size="large">¡Ven a probar!</Button></a>
                </div>
            </div>
            <div className='mosaicoMobile'>
                <div
                    className={`item textoMobile ${firstItemVisible ? 'visible' : ''}`}
                    ref={firstItemRef}
                >
                    <h2>¿Qué es el Kendo?</h2>
                    <div>
                        <p>Kendo, que significa "el camino de la espada" en japonés, es un arte marcial moderno que se basa en las técnicas y la filosofía de la esgrima tradicional de los samuráis.</p>
                        <p>En Kendo, los practicantes utilizan espadas de bambú (shinai) y visten una armadura protectora (bogu), mientras desarrollan habilidades físicas y mentales a través de la disciplina, la concentración y el respeto.</p>
                        <p>El Kendo no solo mejora la condición física, sino que también afina la concentración y la rapidez de reacción, convirtiéndolo en un entrenamiento completo para el cuerpo y la mente.</p>
                        <p>En nuestro gimnasio, ofrecemos clases de Kendo para todos los niveles, desde principiantes hasta avanzados, en un ambiente que fomenta el respeto mutuo y el crecimiento personal.</p>
                    </div>
                </div>
                <div>
                    <img className='imagenWeb' src={que_es} alt="que es kendo zaragoza" />
                </div>
                <div
                    className={`item textoMobile ${secondItemVisible ? 'visible' : ''}`}
                    ref={secondItemRef}
                >
                    <h2>Sobre nosotros</h2>
                    <div>
                        <p>Somos un grupo diverso de entusiastas del kendo, unidos por nuestra pasión y con ganas de darse palos.</p>
                        <p>Con años de práctica y enseñanza, nuestros miembros ostentan múltiples danes (cinturones negros) y un amplio historial de títulos en competiciones.</p>
                        <p>Nos dedicamos a impartir kendo de manera precisa y apasionada, sin dejar de lado su aspecto más competitivo. Adaptamos nuestras enseñanzas a las necesidades de cada individuo, ya sea que busquen aprender kendo por placer o aspiren a destacarse en competiciones, siempre fomentando que ambos caminos puedan complementarse.</p>
                    </div>
                </div>
                <div>
                    <img className='imagenWeb' src={nosotros} alt="nosotros kendo zaragoza" />
                </div>
            </div>
            <div className='imagenesMobile'>
                <h2>IMÁGENES</h2>
                <div className='grid'>
                    {images.map((item, index) => {
                        return (
                            <img onClick={() => handleClick(item)} className='item' src={item} alt={item + " kendo zaragoza"} key={index} />
                        )
                    })}
                </div>
                {selectedImage && (
                    <div className="overlay" onClick={handleClose}>
                        <div className="overlay-content">
                            <img src={selectedImage} alt="Selected kendo zaragoza" />
                        </div>
                    </div>
                )}
            </div>
            <div id='contacto' className='contactoMobile'>
                <h2>CONTACTO</h2>
                <div>
                    <div className='titulo'>
                        <FmdGoodIcon />
                        <h3>Dirección</h3>
                    </div>
                    <div className='contenido'>
                        <a href="https://maps.app.goo.gl/cvWHDRYEz9s4MQac9" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                            <p>Dojo Dento Ryu</p>
                            <p>Av. Cataluña 108, Zaragoza</p>
                            <p><a href="https://dojodentoryu.com/" target="_blank" rel="noopener noreferrer">Web</a></p>
                        </a>
                    </div>
                </div>
                <div>
                    <div className='titulo'>
                        <QueryBuilderIcon />
                        <h3>Horario</h3>
                    </div>
                    <div className='contenido'>
                        <p>Lunes: 20:00-21:30</p>
                        <p>Miércoles: 20:00-21:30</p>
                    </div>
                </div>
                <div>
                    <div className='titulo'>
                        <EmailIcon></EmailIcon>
                        <h3>Formulario</h3>
                    </div>
                    <form className='campos' onSubmit={handleSubmit}>
                        <TextField
                            id="standard-basic"
                            label="Nombre"
                            variant="standard"
                            name="nombre"
                            value={formData.nombre}
                            onChange={handleInputChange}
                            required
                        />
                        <TextField
                            id="standard-basic"
                            label="Teléfono"
                            variant="standard"
                            name="telefono"
                            value={formData.telefono}
                            onChange={handleInputChange}
                            required
                        />
                        <TextField
                            id="standard-basic"
                            label="Correo"
                            variant="standard"
                            name="correo"
                            value={formData.correo}
                            onChange={handleInputChange}
                            required
                            type='email'
                        />
                        <TextField
                            id="standard-textarea"
                            label="Mensaje"
                            multiline
                            variant="standard"
                            maxRows={3}
                            name="mensaje"
                            value={formData.mensaje}
                            onChange={handleInputChange}
                            required
                        />
                        <Button type="submit" variant="contained">Enviar</Button>
                    </form>
                </div>
            </div>
            <div className='pieMobile'></div>
        </div>
    );
};

export default HomeMobile;
