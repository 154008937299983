import './App.css';
import Home from './components/home/Home';
import HomeMobile from './components/homeMobile/HomeMobile';

function App() {

  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return (
      <div className="App">
        <HomeMobile />
      </div>
    )

  } else {
    return (
      <div className="App">
        <Home />
      </div>
    );
  }
}

export default App;
