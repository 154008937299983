import React, { useState, useEffect } from 'react';
import './Home.scss';
import { Button, TextField } from '@mui/material';
import portadaImage from '../../assets/img/portada.webp';
import que_es from '../../assets/img/que_es.webp';
import nosotros from '../../assets/img/nosotros.webp';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import EmailIcon from '@mui/icons-material/Email';


const Home = () => {
    function importAll(r) {
        return r.keys().map(r);
    }

    const images = importAll(require.context('../../assets/img/grid/webp', false, /\.webp$/));
    const [selectedImage, setSelectedImage] = useState(null);

    const [formData, setFormData] = useState({
        nombre: '',
        telefono: '',
        correo: '',
        mensaje: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateForm = () => {
        const { nombre, telefono, correo, mensaje } = formData;
        return nombre && telefono && correo && mensaje;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateForm()) {
            toast.error("Todos los campos son obligatorios");
            return;
        }

        emailjs.send(
            'service_yg57weq',
            'template_05jjez3',
            formData,
            'pq6xSpapgHBxGBFJI'
        ).then((result) => {
            toast.success("Mensaje enviado correctamente");
        }, (error) => {
            toast.error("Error al enviar el mensaje");
        });

        setFormData({
            nombre: '',
            telefono: '',
            correo: '',
            mensaje: ''
        });
    };

    const handleClick = (image) => {
        setSelectedImage(image);
    };

    const handleClose = () => {
        setSelectedImage(null);
    };

    const handleScroll = (event) => {
        event.preventDefault();
        const sections = ['cabecera', 'que-es-el-kendo', 'imagenes', 'contacto'];
        const currentSectionIndex = sections.findIndex(section => {
            const element = document.getElementById(section);
            const rect = element.getBoundingClientRect();
            return rect.top <= window.innerHeight && rect.bottom >= 0;
        });

        if (event.deltaY > 0 && currentSectionIndex < sections.length - 1) {
            document.getElementById(sections[currentSectionIndex + 1]).scrollIntoView({ behavior: 'smooth' });
        } else if (event.deltaY < 0 && currentSectionIndex > 0) {
            document.getElementById(sections[currentSectionIndex - 1]).scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        window.addEventListener('wheel', handleScroll, { passive: false });
        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, []);

    return (
        <div>
            <ToastContainer />
            <div id='cabecera' className='cabecera'>
                <div>Kendo Zaragoza</div>
                <div className='grupo'>
                    <a href="#que-es-el-kendo"><Button variant="text">¿Qué es el kendo?</Button></a>
                    <a href="#que-es-el-kendo"><Button variant="text">Sobre nosotros</Button></a>
                    <a href="#imagenes"><Button variant="text">Imagenes</Button></a>
                    <a href="#contacto"><Button variant="text">Contacto</Button></a>
                </div>
            </div>
            <div id='portada' className='portada'>
                <img className='imagen' src={portadaImage} alt="portada kendo zaragoza" />
                <div>
                    <h1>Kendo Zaragoza</h1>
                    <p>Descubre la magia del kendo en Zaragoza</p>
                    <a href="#contacto">  <Button variant="contained" color='secondary' size="large">¡Ven a probar!</Button></a>
                </div>
            </div>
            <div id='que-es-el-kendo' className='mosaico'>
                <div className='grupo'>
                    <img className='imagen' src={que_es} alt="que es kendo zaragoza" />
                    <div className='item texto'>
                        <h2 className='grande'>
                            ¿Qué es el Kendo?
                        </h2>
                        <div className='pequeño'>
                            <p>Kendo, que significa "el camino de la espada" en japonés, es un arte marcial moderno que se basa en las técnicas y la filosofía de la esgrima tradicional de los samuráis.</p>
                            <p>En Kendo, los practicantes utilizan espadas de bambú (shinai) y visten una armadura protectora (bogu), mientras desarrollan habilidades físicas y mentales a través de la disciplina, la concentración y el respeto.</p>
                            <p>El Kendo no solo mejora la condición física, sino que también afina la concentración y la rapidez de reacción, convirtiéndolo en un entrenamiento completo para el cuerpo y la mente.</p>
                            <p>En nuestro gimnasio, ofrecemos clases de Kendo para todos los niveles, desde principiantes hasta avanzados, en un ambiente que fomenta el respeto mutuo y el crecimiento personal.</p>
                        </div>
                    </div>
                </div>
                <div className='grupo'>
                    <div className='item texto'>
                        <h2 className='grande'>
                            Sobre nosotros
                        </h2>
                        <div className='pequeño'>
                            <p>
                                Somos un grupo diverso de entusiastas del kendo, unidos por nuestra pasión y con ganas de darse palos.
                            </p>
                            <p>
                                Con años de práctica y enseñanza, nuestros miembros ostentan múltiples danes (cinturones negros) y un amplio historial de títulos en competiciones.
                            </p>
                            <p>
                                Nos dedicamos a impartir kendo de manera precisa y apasionada, sin dejar de lado su aspecto más competitivo. Adaptamos nuestras enseñanzas a las necesidades de cada individuo, ya sea que busquen aprender kendo por placer o aspiren a destacarse en competiciones, siempre fomentando que ambos caminos puedan complementarse.
                            </p>
                        </div>
                    </div>
                    <img className='imagen' src={nosotros} alt="nosotros kendo zaragoza" />
                </div>
            </div>
            <div id='imagenes' className='imagenes'>
                <h2 className='titulo'>
                    IMÁGENES
                </h2>

                <div className='grid'>
                    {images.map((item, index) => {
                        return (
                            <img onClick={() => handleClick(item)} className='item' src={item} alt={item + " kendo zaragoza"} key={index} />
                        )
                    })}
                </div>
                {selectedImage && (
                    <div className="overlay" onClick={handleClose}>
                        <div className="overlay-content">
                            <img src={selectedImage} alt="Selected kendo zaragoza" />
                        </div>
                    </div>
                )}
            </div>
            <div id='contacto' className='contacto'>
                <h2 className='text'>CONTACTO</h2>
                <div className='grupoContacto'>
                    <div className='grupoIzq'>
                        <div>
                            <div className='titulo'>
                                <FmdGoodIcon />
                                <h3>
                                    Dirección
                                </h3>
                            </div>
                            <div className='contenido'>
                                <a href="https://maps.app.goo.gl/cvWHDRYEz9s4MQac9" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <p>
                                        Dojo Dento Ryu
                                    </p>
                                    <p>
                                        Av. Cataluña 108, Zaragoza
                                    </p>
                                    <p>
                                        <a href="https://dojodentoryu.com/" target="_blank" rel="noopener noreferrer">
                                            Web
                                        </a>
                                    </p>
                                </a>
                            </div>
                        </div>
                        <div>
                            <div className='titulo'>
                                <QueryBuilderIcon />
                                <h3>
                                    Horario
                                </h3>
                            </div>
                            <div className='contenido'>
                                <p>
                                    Lunes: 20:00-21:30
                                </p>
                                <p>
                                    Miercoles: 20:00-21:30
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='titulo'>
                            <EmailIcon></EmailIcon>
                            <h3>
                                Formulario
                            </h3>
                        </div>
                        <form className='campos' onSubmit={handleSubmit}>
                            <div className='superior'>
                                <TextField
                                    id="standard-basic"
                                    label="Nombre"
                                    variant="standard"
                                    name="nombre"
                                    value={formData.nombre}
                                    onChange={handleInputChange}
                                    required
                                />
                                <TextField
                                    id="standard-basic"
                                    label="Teléfono"
                                    variant="standard"
                                    name="telefono"
                                    value={formData.telefono}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className='intermedio'>
                                <TextField
                                    id="standard-basic"
                                    label="Correo"
                                    variant="standard"
                                    name="correo"
                                    value={formData.correo}
                                    onChange={handleInputChange}
                                    required
                                    type='email'
                                />
                            </div>
                            <div className='inferior'>
                                <TextField
                                    id="standard-textarea"
                                    label="Mensaje"
                                    multiline
                                    variant="standard"
                                    maxRows={3}
                                    name="mensaje"
                                    value={formData.mensaje}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div>
                                <Button type="submit" variant="contained">Enviar</Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className='pie' >
                <h2> Kendo Zaragoza</h2>

            </div>
        </div>
    );
}

export default Home;
